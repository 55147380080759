body {
  margin: 0;
}

.loading-spinner__spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -20px;
  margin-top: -20px;
}
.loading-spinner__text {
  position: absolute;
  width: 500px;
  height: 50px;
  left: 50%;
  top: 50%;
  margin-left: -250px !important;
  margin-top: 40px !important;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 500;
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}
