.fc-toolbar {
  background-color: #bb5e00;
  color: white; /* 文本颜色，根据需要调整 */
}
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0px;
}
.fc .fc-button-primary {
  background-color: #bb5e00; /* 背景颜色 */
  border-color: #bb5e00; /* 外框顏色 */
  color: white; /* 文本颜色 */
  outline: none;
}

/* 悬停状态下的样式 */
.fc .fc-button-primary:hover {
  background-color: #bb5e00; /* 背景颜色 */
  border-color: #bb5e00; /* 外框顏色 */
}
.fc .fc-button-primary:disabled {
  background-color: #bb5e00; /* 背景颜色 */
  border-color: #bb5e00; /* 外框顏色 */
}
.fc .fc-button-primary:not(:disabled):active {
  background-color: #bb5e00; /* 背景颜色 */
  border-color: #bb5e00; /* 外框顏色 */
  color: white; /* 文本颜色 */
}
.fc .fc-button-primary:not(:disabled):active {
  background-color: #bb5e00; /* 背景颜色 */
  border-color: #bb5e00; /* 外框顏色 */
  color: white; /* 文本颜色 */
}
.fc .fc-button-primary:not(:disabled):focus {
  background-color: #bb5e00; /* 背景颜色 */
  border-color: #bb5e00; /* 外框顏色 */
  color: white; /* 文本颜色 */
  box-shadow: none;
}
.fc-col-header-cell {
  background-color: #bb5e00; /* 背景颜色 */
  color: white; /* 文本颜色 */
}
.fc-day-today {
  background-color: #ffffff !important;
}
.circle {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: #bb5e00;
  color: white;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
}
