.yatung-paper-with-ribbon {
  position: relative;
}

.yatung-paper-with-ribbon .ribbon {
  position: absolute;
  height: 100px;
  width: 100px;
  background: linear-gradient(135deg, rgba(189, 94, 0, 1) 50%, transparent 50%);
  overflow: hidden;
}

.yatung-paper-with-ribbon.first .ribbon {
  border-top-left-radius: 4px;
}

.yatung-paper-with-ribbon .cementitious-ribbon::before {
  content: '膠結材';
  position: absolute;
  top: 28px;
  left: -10px;
  height: 24px;
  width: 100%;
  background: transparent;
  transform: rotate(-45deg);
  display: flex;
  justify-content: center;
  align-items: center;

  color: #ffffff;
  font-family: 'Microsoft JhengHei';
  font-size: 20px;
  font-weight: 900;
  line-height: 24px;
  text-indent: 0.48em;
  letter-spacing: 0.48em;
}

.yatung-paper-with-ribbon .chemical-ribbon::before {
  content: '化學摻劑';
  position: absolute;
  top: 28px;
  left: -10px;
  height: 24px;
  width: 100%;
  background: transparent;
  transform: rotate(-45deg);
  display: flex;
  justify-content: center;
  align-items: center;

  color: #ffffff;
  font-family: 'Microsoft JhengHei';
  font-size: 20px;
  font-weight: 900;
  line-height: 24px;
  text-indent: 0.16em;
  letter-spacing: 0.16em;
}

.yatung-paper-with-ribbon .sand-and-rock-ribbon::before {
  content: '砂石';
  position: absolute;
  top: 28px;
  left: -10px;
  height: 24px;
  width: 100%;
  background: transparent;
  transform: rotate(-45deg);
  display: flex;
  justify-content: center;
  align-items: center;

  color: #ffffff;
  font-family: 'Microsoft JhengHei';
  font-size: 20px;
  font-weight: 900;
  line-height: 24px;
  text-indent: 0.8em;
  letter-spacing: 0.8em;
}

.yatung-paper-with-ribbon .content {
  padding-top: 30px;
  padding-bottom: 40px;
  padding-left: 8px;
  margin-right: 12px;
}

.yatung-paper-with-ribbon.divider .content {
  border-top: #7c878e 2px dashed;
}

.scroll-bar::-webkit-scrollbar {
  width: 7px;
}

.scroll-bar::-webkit-scrollbar-button {
  background: transparent;
}

.scroll-bar::-webkit-scrollbar-track-piece {
  background: transparent;
}

.scroll-bar::-webkit-scrollbar-thumb {
  border-radius: 7px;

  background-color: #bb5e00;
}

.scroll-bar::-webkit-scrollbar-track {
  box-shadow: transparent;
}

.img-wrap {
  position: relative;
  display: inline-block;
  margin: 10px 5px;
  width: 100px;
  height: 100px;
  border: 1px solid #d9d9d9;
  overflow: hidden;
}

/* 为了让img垂直居中 */
.img-wrap:after {
  display: inline-block;
  content: '';
  height: 100%;
  vertical-align: middle;
}

.img-wrap img {
  vertical-align: middle;
  width: 100%;
}
.img-wrap > span {
  position: absolute;
  top: 0;
  right: 0px;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 16px;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.img-wrap > span::after {
  display: inline-block;
  content: 'x';
  color: #fff;
  font-size: 12px;
}

label {
  cursor: pointer;
}
label > input {
  display: none;
}
